.black-background {
    position: fixed; /* Fixed positioning */
    top: 0; /* Positioned at the top */
    left: 0; /* Positioned on the left */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: black;
    /* Black background */
    z-index: -1;
  }
  