.existing-paths {
  /* color: green; */
  flex: 1;
  gap: 2ex;
  display: flex;
  height: 100%;
  /* align-self: stretch; */
  /* overflow-x: auto; */
}
.existing-paths.NEXT {
  align-items: flex-end;
}
.existing-paths.PREV {
  align-items: flex-start;
}
