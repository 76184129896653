.forest-path {
  /* display: flex;
    width: fit-content; */
  flex: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start */
  width: max-content;
  /* min-height: fit-content; */
  max-height: 100%;
}
.forest-path.NEXT {
  flex-direction: column;
}
.forest-path.PREV {
  flex-direction: column-reverse;
}
