.new-path-button {
    /* color: blue; */
    padding: 3ex;
    border: var(--white-border);
    /* same height as forest thought */
    min-height: 60pt;
   height: 19ex;
    max-height: 30vh;
    margin-right: 2ex;

    display:flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    /* color: rgb(170,170,170); */
    /* background-color: rgb(20,20,20); */

}
