.inbox-item-container {
  display: flex;
  flex-direction: column;
  margin: 1em;
  position: relative;
}

.thought-title-link{
  cursor: pointer;
  text-decoration: underline;
}
