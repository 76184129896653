button.audio-recorder-button {
  width: 88px;
  height: 88px;
  padding: 3ex;
  border: 1px solid black;
}

.button.audio-recorder-button--recording {
  border-color: black;
}

@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}
.button.audio-recorder-button--sending {
  border: 0;
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.audio-recorder-button--sending::before {
  content: '';
  position: absolute;
  z-index: -2;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(black, black), linear-gradient(black, black);
  animation: rotate 2s linear infinite;
}
.audio-recorder-button--sending::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: white;
  border-radius: 50%;
}

.record-small {
  transform: scaleX(-1);
}
