.forest-thought {
  /* flex-shrink: 0; */
  border: var(--white-border);
  padding: 1ex 1.2ex;
  min-height: 60pt;
  height: 19ex;
  max-height: 30vh;
  min-width: 100pt;
  width: 40ex;
  max-width: 80vw;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: black;
  /* border: none; */
}
.forest-thought-author-name {
  opacity: 0.4;
  margin-bottom: 0.5ex;
}
.forest-thought.isClickable {
  cursor: pointer;
}
.forest-thought.isClickable:active {
  /* border-color: transparent; */
}
