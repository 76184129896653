.queen-thought {
  /* color: blue */
  /* border: var(--white-border); */
  /* font-size: 1.2em; */
  flex: 1;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
}

.queen-thought > .forest-thought {
  /* border: none; */
}
