.forest-path-caption {
    /* display: flex;; */
    text-align: center;
    padding: 1ex;
    /* max-height: 6ex; */
    height: 6ex;
    font-style: italic;
    overflow-wrap: break-word;
    /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
    /* flex-shrink: 1; */
    /* width: min-content; */
    /* max-width: 100%; */
}
.forest-path-caption.empty {
    opacity: .5;
}
.forest-path-caption.invisible{
    opacity: 0;
}
