.forest-landing {
    height: 100vh;
    width: 100vw;
    border-radius: 0;
    background: black;
}

.forest-image {
  background-image: url('branchesfreddie.png');
  background-size: cover;
    animation: fadeIn 60s;
    z-index: -1;
    opacity: .2;
} 

.forest-entrance {
  display: flex;
  align-items: center;
  margin: 1em;
  cursor: pointer;
  transition: font-size 2s;
  /* animation: fadeIn 2s ease-in; */
  font-size: .5em;
  letter-spacing: 0;
  z-index: 1;
  opacity: .4;
  /* font-style: italic; */
  border-radius: 2pt;
  padding: 1ex;
}


  
  
  .forest-entrance:hover{
    /* text-decoration: underline; */
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 1;


  }
  .forest-entrance:active {
    background-color: rgba(255, 255, 255, .14);

    /* font-size: 3em; */
    /* color: transparent */
    /* background-color: rgba(255, 255, 255, 0.347); */

    /* text-decoration: underline; */
  }
  @keyframes fadeIn {
    0% {opacity: 0.04;}
    100% {opacity: .2;}
  }
  @keyframes fadeInHalf {
    0% {opacity: 0.04;}
    100% {opacity: .5;}
  }
  .forest-text {
    /* animation: fadeInFull 12s cubic-bezier(0.25, 0.46, 0.45, 0.94); */
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 3em;
    text-align: center;
    letter-spacing: .5ex;
    z-index: 1;
    gap: .5ex;
  }

  @keyframes spacen {
    0% {letter-spacing: 0;}
    100% {letter-spacing:1ex;}
  }
