/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap'); */

:root {
}

.App {
  word-break: break-word;
  height: 100vh;
  width: 100vw;
  -ms-overflow-style: none; 

   
}

::-webkit-scrollbar {
  width: 0pt; 
  background-color: transparent; 
}

.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}


