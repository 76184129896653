:root {
  --border-color: rgb(70, 70, 70);
  --text-color: white;
  --white-border: var(--border-color) solid 1px;
}

.local-forest-view {
  width: 100vw;
  height: 100vh;
  font-size: 1.7ex;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--text-color);
}
.local-forest-view > div {
  padding: 1ex;
}
.existing-paths-container {
  display: flex;
  flex: 1;
  overflow-x: auto;
}
.existing-paths-container.NEXT {
  align-items: flex-end;
}
