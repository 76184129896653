.editor-container {
  width: 100%;
  border-radius: 0.5em;
  color: rgb(130, 130, 130);
  color: black;
  display: flex;
  flex-direction: column;
}
.editor-container > div {
  height: 100%;
}
