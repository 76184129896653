:root {
  --bubble: 0 0 .3em rgb(184, 184, 184);
  --airy-smoke:  rgb(250, 250, 250);

}
.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.row {
  display: flex;

  flex-direction: row;
  justify-content: center;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.panel {
  flex: 1;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 500pt;
  margin: 0 auto;
}
.panel-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.secondary-panel {
  padding: 0;
}


@keyframes extra-pulse  {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse-slow  {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.pulse, .extra-pulse {
  animation: pulse 1.5s infinite;
}
.pulse-slow{
  animation: pulse 2.5s infinite;
}
.spinner {
  animation: spin 2s linear infinite;
}
.slow-spinner {
  animation: spin 200s linear infinite;
}
.slow-spinner.extra-pulse {
  animation:  spin 4s linear infinite,  pulse 1.5s infinite;
}
.textPost {
  max-width: 500pt;
  min-width: 80pt;
  height: fit-content;
  padding: 0.6em;
  border: 0.01em solid gainsboro;
  border-radius: 2pt;
  flex: 1;
  position: relative;
  box-shadow: 0 0 1ex gainsboro;
  margin: 10pt;
  flex:0;
  background-color: white;
}
@keyframes oscillate {
  0%, 100% { outline-color: rgba(255, 0, 0, 0.486); }
  20% { outline-color: rgba(255, 0, 128, 0.6); }
  40% { outline-color: rgba(128, 0, 255, 0.3); }
  60% { outline-color: rgba(0, 128, 255, 0.6); }
  80% { outline-color: rgba(0, 255, 128, 0.3); }
}



.textPost.playing {
  /* box-shadow: 0 0 1ex red; */
  outline: 4px solid;
  animation: oscillate 8s infinite;

}
.textPost.breadcrumb {
  opacity: .6;
  box-shadow: none;
  margin: .5ex 0;
}

.textPost:not(.expanded){

  cursor: pointer;
}
.textPost:not(.expanded):active{
  
  outline: solid 1px gray;
}
.textPost.expanded {

  box-shadow: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px gainsboro solid;

  border-radius: 0;
  margin: 0;
  /* border-color: black; */
  display: flex;
  flex-direction: column;
max-height: fit-content;
box-shadow: none;


}

.textPost:not(.breadcrumb) + .textPost:not(.breadcrumb),
.feed-item-with-connect + .feed-item-with-connect {
  margin-top: 1em;
}
.textPost.personPreview {
  min-height: 42pt;
}
.app-view-toggle {
  margin: 1ex 0 0;
}
.app-view-toggle > * {
  cursor: pointer;
  color: rgb(181, 181, 181);
  font-size: 0.9em;
  border-radius: 2pt;
  padding: 0.35em;
}
.toggle-option.selected {
  color: gray;
  background-color: rgb(240, 240, 240);
}

.bold-inbox {
  font-weight: bold;
  color: black;
}
