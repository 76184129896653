.audio-thought-container {
  cursor: pointer;
}

.delete-post-trash {
  color: rgb(200, 200, 200);
  margin-left: 6px;
}

.delete-post-trash:hover {
  color: black;
}
.textpost-title {
  width: fit-content;
  margin: .6ex 0;
  font-size: 1.1em;
  cursor: default;
}
 
.textpost-title.mine { 
  cursor: pointer;
}
.author-name-in-post {
  color: inherit;
  text-decoration: none;
  cursor: pointer;


}
.author-name-in-post:hover {
text-decoration: underline;
}

.mini-truncated-display {
  height: fit-content;
  color: rgb(161, 161, 161);
  font-style: italic;
  font-size: .9em;
  margin-left: 2px;
}

.expanded-related-thoughts-header {
  color: rgb(200, 200, 200);
  margin: 1ex;
  font-style: italic;
  font-size: 1.2em;
}

.two-columns {
  display: flex;
  justify-content: space-between;
}

.button-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
